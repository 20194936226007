var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('div',{staticClass:"row"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_ORDERS))?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List PurchasesOrders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-bag-17"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.PURCHASES_ORDERS")))])])]}}],null,false,1616478784)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_INVOICES)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List PurchasesInvoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-single-copy-04"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.PURCHASES_INVOICES")))])])]}}],null,false,557582027)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_PAYMENTS)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List PurchasesPayments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"ni ni-2x ni-money-coins"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.PURCHASES_PAYMENTS")))])])]}}],null,false,2475741662)}):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_DELIVERIES)
        )?_c('router-link',{staticClass:"col-4 shortcut-item",attrs:{"to":{ name: 'List PurchasesDeliveries' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"shortcut-media avatar avatar-xl rounded-circle bg-info"},[_c('i',{staticClass:"fa fa-2x fa-truck"})]),_c('small',[_vm._v(_vm._s(_vm.$t("COMMON.PURCHASES_DELIVERIES")))])])]}}],null,false,2954668083)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }